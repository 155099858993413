<template>
  <b-container fluid>
    <button-back />
    <b-row>
      <b-col cols="12">
        <h3>Tutoriales</h3>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col
        v-if="hasPermission(module, 'new')"
        lg="12"
        md="12"
        sm="12"
        class="mb-2"
      >
        <b-button size="sm" variant="success" @click="handleCreating">
          <b-icon-plus-square class="mr-1" />
          Nuevo
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="isBusy">
      <b-col lg="12" md="12" sm="12">
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Cargando datos...</strong>
        </div>
      </b-col>
    </b-row>
    <section v-else>
      <div v-if="records.length > 0">
        <list-tutorial
          :records="records"
          @editing="handleEditing"
          @deleting="handleDeleting"
        />
      </div>
      <div v-else>
        <b-row class="mb-2">
          <b-col lg="12" md="12" sm="12">
            <div class="text-center my-2">
              <strong>No hay datos para mostrar.</strong>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>
    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-tutorial
          :record="record"
          :isDisabled="isDisabled"
          @save="handleLoadTutorials"
        />
      </template>
    </base-modal>
  </b-container>
</template>

<script>
import ActionCRUDsp from "@/mixins/ActionCRUDsp";
import {
  BContainer,
  BRow,
  BCol,
  BIconPlusSquare,
  BButton,
  VBToggle,
  BSpinner,
} from "bootstrap-vue";
import ButtonBack from "@/components/buttons/ButtonBack";
import PermissionAction from "@/mixins/PermissionAction";
import FormTutorial from "./components/FormTutorial.vue";
import BaseModal from "@/components/base/BaseModal";
import ListTutorial from "./components/ListTutorial";

export default {
  inject: ["tutorialRepository"],
  mixins: [PermissionAction, ActionCRUDsp],
  directives: {
    "b-toggle": VBToggle,
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BIconPlusSquare,
    BButton,
    BSpinner,
    ButtonBack,
    BaseModal,
    FormTutorial,
    ListTutorial,
  },
  data() {
    return {
      module: "59e59519-7182-4595-830f-363123edbdd9",
      repository: "tutorialRepository",
      records: [],
      record: null,
      isBusy: true,
      isDisabled: false,
      title: "Tutorial",
      id: "modal-tutorial",
    };
  },
  methods: {
    async handleLoadTutorials() {
      const me = this;
      me.$bvModal.hide(me.id);
      me.records = [];

      try {
        me.isBusy = true;

        const { data } = await me[me.repository].getAll();
        data?.forEach((el) => {
          const record = me.records.find(
            (record) => record.moduleUuid == el.moduleUuid
          );

          if (record) {
            record.links.push({
              uuid: el.uuid,
              link: el.link,
              name: el.name,
            });
          } else {
            me.records.push({
              moduleUuid: el.moduleUuid,
              nameMod: el.module.name_spanish,
              links: [
                {
                  uuid: el.uuid,
                  link: el.link,
                  name: el.name,
                },
              ],
            });
          }
        });
      } catch (error) {
        me.records = [];
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
    handleCreating() {
      const me = this;
      me.record = null;
      me.isDisabled = false;
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.record = item;
      me.isDisabled = false;
      me.$bvModal.show(me.id);
    },
    async handleDeleting(uuid) {
      const me = this;
      me.$bvModal
        .msgBoxConfirm("¿Seguro que deseas eliminar este registro?", {
          title: "Confirmar eliminación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            try {
              await me.tutorialRepository.delete(uuid);

              me.messageSuccess(
                "Registro eliminado",
                "Registro eliminado con exito"
              );
              me.handleLoadTutorials();
            } catch (error) {
              const { status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
            }
          }
        });
    },
  },
  async mounted() {
    const me = this;
    me.handleLoadTutorials();
  },
};
</script>
<style lang="css">
.card-tutorial {
  background: transparent !important;
  border: none !important;
}
.btn-collapse-tutorial {
  background: transparent !important;
  color: var(--black-color) !important;
  font-size: 18px;
  border: none !important;
  justify-content: flex-start !important;
  font-weight: 600 !important;
}
</style>
