<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="name" class="label_input">Nombre*</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Nombre"
            v-model="formData.name"
            :disabled="isDisabled"
            autofocus
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Modulo*</label>
          <v-select
            :options="modules"
            :reduce="(option) => option.uuid"
            label="name_spanish"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.moduleUuid"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="link" class="label_input">Link - Embed*</label>
          <b-form-input
            id="link"
            size="sm"
            placeholder="Link - Embed"
            v-model="formData.link"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer" v-if="!isDisabled">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";
import vSelect from "vue-select";

export default {
  inject: ["tutorialRepository", "moduleRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      formData: {
        name: "",
        moduleUuid: null,
        link: "",
      },
      repository: "tutorialRepository",
      modules: [],
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
      moduleUuid: {
        required,
      },
      link: {
        required,
      }
    },
  },
  methods: {
    async loadModules() {
      const me = this;
      try {
        const { data } = await me.moduleRepository.getAll();
        me.modules = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
  },
  async mounted() {
    const me = this;
    await me.loadModules();
    if (me.record) {
      me.formData = { ...me.formData, ...me.record };
    }
  },
};
</script>
